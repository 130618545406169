<template>
    <div class="page_wrapper form">
        <div class="form_inner">
            <div class="page_labels mb-7">
                <div class="header">
                    <h1 class="title text-center mb-2">
                        {{ $t('auth.password_recovery') }}
                    </h1>
                    <p class="description text-center">
                        {{ $t('auth.enter_email_recovery') }}
                    </p>
                </div>
            </div>

            <a-form-model
                ref="authForm"
                :model="form"
                layout="vertical"
                autocomplete="off"
                :rules="rules">
                <a-form-model-item
                    ref="email"
                    :label="$t('auth.enter_email')"
                    class="mb-2"
                    prop="email">
                    <a-input
                        v-model="form.email"
                        ref="loginEmail"
                        type="email"
                        :placeholder="$t('auth.placeholder_email')"
                        autocomplete="username"
                        size="large"
                        @pressEnter="captchaCheck()" />
                </a-form-model-item>
                <a-button
                    type="primary"
                    :loading="loading"
                    size="large"
                    ghost
                    block
                    @click="captchaCheck()">
                    {{ $t('auth.recover_password') }}
                </a-button>
                <p class="mt-5 text-center">
                    <router-link 
                        :to="{name: 'login'}"
                        class="text-black">
                        {{ $t('auth.login_portal') }}
                    </router-link>
                </p>
            </a-form-model>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
    computed: {
        ...mapState({
            usersAuth: state => state.user.usersAuth,
            loginProgress: state => state.loginProgress
        }),
        loginEmail() {
            return this.$refs['loginEmail']
        },
        loginPassword() {
            return this.$refs['loginPassword']
        },
        authConfig() {
            return this.$store.state.user.authConfig
        }
    },
    data() {
        return {
            selected: [],
            loading: false,
            form: {
                email: '',
            },
            rules: {
                email: [
                    { required: true, message: this.$t('auth.field_required'), trigger: 'change' },
                    { max: 255, message: this.$t('auth.required_sym', { sym: 255 }), trigger: 'change' },
                ],
            }
        }
    },
    metaInfo() {
        return {
            title: this.$t('auth.password_recovery_email_title')
        }
    },
    methods: {
        ...mapActions({
            init: 'user/init'
        }),
        backStep() {
            this.$router.push({ name: 'forgotPassword' })
        },
        selectUser(user) {
            this.selected = [user.email]
            this.form.email = user.email
            this.$nextTick(() => {
                this.loginPassword.focus()
            })
        },
        captchaCheck() {
            if(this.authConfig.reCAPTCHASiteKey) {
                grecaptcha.ready(() => {
                    this.loading = true
                    grecaptcha.execute(this.authConfig.reCAPTCHASiteKey, {action: 'forgot_pass'}).then((token) => {
                        this.formSubmit(token)
                    })
                })
            } else {
                this.formSubmit()
            }
        },
        formSubmit(captcha = null) {
            this.$refs.authForm.validate(async valid => {
                if (valid) {
                    try {
                        this.loading = true
                        let formData = JSON.parse(JSON.stringify(this.form))
                        if(captcha)
                            formData.captcha = captcha
                        else
                            formData.captcha = 'captcha'
                        await this.$http.post('users/forgot_password/', formData)
                        this.$message.success(this.$t('auth.recovery_link_sent'))
                        this.$router.push({name: 'login'})
                    } catch(error) {
                        if(error.non_field_errors){
                            this.$message.error(String(error.non_field_errors), 4)
                        }
                        if(error.message){
                            this.$message.error(String(error.message), 4)
                        }
                        console.log(error, 'formSubmit')
                    } finally {
                        this.loading = false
                    }
                } else {
                    this.$message.warning(this.$t('auth.fill_required_fields'))
                    this.loading = false
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
$breakpoint-1: 1200px;
$breakpoint-2: 680px;

.header {
    margin-bottom: 30px;
    color: #0C0C0E;
}
.title {
    color: #0C0C0E;
    font-size: 2rem;
}
.description {
    font-size: 1rem;
}

.form {
    padding: 40px 50px;
    background: #ffffffb2;
    
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    
    border-radius: 20px;
    margin-top: 200px;
    margin: 0 auto;

    @media (max-width: $breakpoint-1) {
        margin-bottom: 50px;
    }

    @media (max-width: $breakpoint-2) {
        padding: 30px 30px;
    }
}
.form_inner {
    width: 470px;
    margin: 0 auto;
    &::v-deep {
        .ant-input,
        .ant-btn {
            height: 50px;
        }
    }
    @media (max-width: $breakpoint-2) {
        max-width: auto;
        width: 100%;
    }
}

</style>